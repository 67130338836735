export default {
    state: {
        bookmarkList: []
    },

    getters: {
        getBookmarkList: state => state.bookmarkList
    },

    mutations: {
        setBookmarkList(state, bookmarks) {
            state.bookmarkList = bookmarks;
        },
        addElementToBookmarkList (state, element) {
            state.bookmarkList.push(element);
            localStorage.setItem("bookmarks", state.bookmarkList.toString());
        },
        removeElementFromBookmarkList (state, element) {
            state.bookmarkList.splice(state.bookmarkList.indexOf(element), 1);
            localStorage.setItem("bookmarks", state.bookmarkList.toString());
        }
    },

    actions: {
        fetchBookmarks({commit}) {
            let bookmarks = localStorage.getItem("bookmarks");
            if(bookmarks === undefined || bookmarks === null || bookmarks === '') {
                commit("setBookmarkList", []);
            }
            else {
                commit("setBookmarkList", bookmarks.split(","));
            }
        }
    }
}
