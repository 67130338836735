import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import { languages } from './i18n/index'
import { defaultLocale } from './i18n/index'
import { store } from './store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@popperjs/core'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "./assets/scss/styles.css"

const messages = Object.assign(languages)

const i18n = createI18n({
    locale: defaultLocale,
    fallbackLocale: 'en',
    messages
})

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(router)
    .use(store)
    .use(i18n)
    .mount('#app')

// make the navbar sticky on top. Add the sticky class to the navbar when you reach its scroll position.
// Remove "sticky" when you leave the scroll position
window.onscroll = function() {myFunction()};
let navbar = document.getElementById("navbar");
let sticky = navbar.offsetTop;

function myFunction() {
    if (window.pageYOffset >= sticky) {
        navbar.classList.add("sticky")
    } else {
        navbar.classList.remove("sticky");
    }
}

window.oncontextmenu = function(event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
};





