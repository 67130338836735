export default {
    state: {
        highlightList: []
    },

    getters: {
        getHighlightList: state => state.highlightList,
        getHighlight: (state) => (id) => {
            return state.highlightList.find(highlight => highlight.id === id);
        }
    },

    mutations: {
        setHighlightList(state, highlights) {
            state.highlightList = highlights;
        },
        updateHighlight(state, payload) {
            state.highlightList.find(element => {
                if(element === payload.highlight) {
                    element.comment = payload.comment;
                    element.color = payload.color;
                    localStorage.setItem("highlights", JSON.stringify(state.highlightList));
                }
            })
        },
        deleteHighlightComment(state, payload) {
            state.highlightList.find(element => {
                if(element === payload.highlight) {
                    element.comment = null;
                    localStorage.setItem("highlights", JSON.stringify(state.highlightList));
                }
            })
        },
        addElementToHighlightList (state, element) {
            state.highlightList.push(element);
            localStorage.setItem("highlights", JSON.stringify(state.highlightList));
        },
        removeElementFromHighlightList(state, element) {
            state.highlightList.splice(state.highlightList.indexOf(element), 1);
            localStorage.setItem("highlights", JSON.stringify(state.highlightList));
        }
    },

    actions: {
        fetchHighlights({commit}) {
            let array = [];
            const highlights = JSON.parse(localStorage.getItem("highlights"));
            if(highlights !== null) {
                for(let highlight of highlights) {
                    array.push({
                        route: highlight.route,
                        range: highlight.range,
                        id: highlight.id,
                        color: highlight.color,
                        content: highlight.content,
                        comment: highlight.comment
                    })
                }
            }
            commit("setHighlightList", array);
        },
    }
}
