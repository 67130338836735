export default {
    state: {
        sideCommentList: []
    },

    getters: {
        getSideCommentList: state => state.sideCommentList,
        getSideCommentFromPageName: (state) => (pageName) => {
            return state.sideCommentList.find(sideComment => sideComment.pageName === pageName);
        },
        getSideCommentContentFromPageName: (state) => (pageName) => {
            if(pageName !== undefined) {
                let element = state.sideCommentList.find(sideComment => sideComment.pageName === pageName);
                if(element !== null && element !== undefined) {
                    if(element.content !== undefined) {
                        return element.content
                    }
                } return null;
            }
            return null;
        }
    },

    mutations: {
        setSideCommentList(state, sideComments) {
            state.sideCommentList = sideComments;
        },
        updateElementInSideCommentList(state, element) {
            state.sideCommentList.find(sideComment => {
                if(sideComment.id === element.id) {
                    sideComment.content = element.content;
                    localStorage.setItem("sideComments", JSON.stringify(state.sideCommentList));
                }
            })
        },
        addElementToSideCommentList (state, element) {
            if(!state.sideCommentList.includes(element)) {
                state.sideCommentList.push(element);
                localStorage.setItem("sideComments", JSON.stringify(state.sideCommentList));
            }
        },
        removeElementFromSideCommentList(state, element) {
            state.sideCommentList.splice(state.sideCommentList.indexOf(element), 1);
            localStorage.setItem("sideComments", JSON.stringify(state.sideCommentList));
        }
    },

    actions: {
        fetchSideComments({commit}) {
            let array = [];
            let sideComments = JSON.parse(localStorage.getItem("sideComments"));
            if(sideComments === undefined || sideComments === null || sideComments === '') {
                commit("setSideCommentList", []);
            }
            else {
                for(let sideComment of sideComments) {
                    array.push({
                        id: sideComment.id,
                        content: sideComment.content,
                        pageName: sideComment.pageName
                    })
                }
                commit("setSideCommentList", array);
            }
        }
    }
}
