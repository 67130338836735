import { createStore } from 'vuex'
import bookmarks from "./modules/bookmarks.js"
import highlights from "./modules/highlights";
import visitedPages from "./modules/visitedPages";
import sideComments from "./modules/sideComments"

export const store = createStore({
    state: {
        isSideMenuOpened: false,
        isSideCommentOpened: false,
        theme: 'light',
    },
    getters: {
        getSideMenuState: state => state.isSideMenuOpened,
        getSideCommentState: state => state.isSideCommentOpened,
        getTheme: state => state.theme
    },
    mutations: {
        setTheme(state, theme) {
            state.theme = theme;
            localStorage.setItem("theme", state.theme);
        },
        toggleSideMenuState(state) {
            state.isSideMenuOpened = !state.isSideMenuOpened;
        },
        toggleSideCommentState(state) {
            state.isSideCommentOpened = !state.isSideCommentOpened;
        },
    },
    actions: {
        fetchTheme({commit}) {
            let theme = localStorage.getItem("theme");
            if(theme === undefined || theme === null || theme === '') {
                commit("setTheme", 'light');
            }
            else {
                commit("setTheme", theme);
            }
        }
    },
    modules: {
        bookmarks,
        highlights,
        visitedPages,
        sideComments
    }
})