export default {
    state: {
        visitedPageList: []
    },

    getters: {
        getVisitedPageList: state => state.visitedPageList
    },

    mutations: {
        setVisitedPageList(state, visitedPages) {
            state.visitedPageList = visitedPages;
        },
        addElementToVisitedPageList (state, element) {
            if(!state.visitedPageList.includes(element)) {
                state.visitedPageList.push(element);
                localStorage.setItem("visitedPages", state.visitedPageList.toString());
            }
        },
        removeElementFromVisitedPageList(state, element) {
            state.visitedPageList.splice(state.visitedPageList.indexOf(element), 1);
            localStorage.setItem("visitedPages", state.visitedPageList.toString());
        }
    },

    actions: {
        fetchVisitedPages({commit}) {
            let visitedPages = localStorage.getItem("visitedPages");
            if(visitedPages === undefined || visitedPages === null || visitedPages === '') {
                commit("setVisitedPageList", []);
            }
            else {
                commit("setVisitedPageList", visitedPages.split(","));
            }
        }
    }
}
